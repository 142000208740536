import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { UserLogin } from 'domain/entities/user';
import { useGlobalState } from 'hooks/global';
import { useAuthHooks } from 'hooks/auth';
import LoginTemplate from 'components/templates/Login';

const LoginPage = (): React.ReactElement => {
  const navigate = useNavigate();

  const { useSnackbarAlert } = useGlobalState();
  const { setAlert } = useSnackbarAlert;

  const { useSignIn } = useAuthHooks();
  const { signInUser } = useSignIn();

  const { mutate: signInMutation, isLoading: isSignInLoading } = useMutation(
    (credentials: UserLogin) => signInUser(credentials),
    {
      onSuccess: () => {
        navigate('/todo');
      },
      onError: () => {
        setAlert({
          severity: 'error',
          children: 'You have entered an invalid email or password. Please try again.',
        });
      },
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleCreateAccount = () => {};

  const handleSignIn = (values: UserLogin) => {
    signInMutation(values);
  };

  return (
    <LoginTemplate
      onCreateAccount={handleCreateAccount}
      onSignIn={handleSignIn}
      isSigningIn={isSignInLoading}
    />
  );
};

export default LoginPage;
