import { Formik, FormikHelpers } from 'formik';
import styled from '@emotion/styled';

import { UserLogin } from 'domain/entities/user';
import LoginForm from 'components/organisms/LoginForm';

import LoginSchema from './Login.validation';

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginFormWrapper = styled.div`
  width: 500px;
`;

type Props = {
  onCreateAccount: () => void;
  onSignIn: (values: UserLogin, formikHelpers: FormikHelpers<UserLogin>) => void;
  isSigningIn?: boolean;
};

const LoginTemplate = ({ onCreateAccount, onSignIn, isSigningIn }: Props): React.ReactElement => {
  const initialValues: UserLogin = {
    email: '',
    password: '',
  };

  return (
    <MainContainer>
      <LoginFormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={LoginSchema}
          onSubmit={onSignIn}
          validateOnMount={false}
        >
          {({ values, errors, touched, setFieldValue, setFieldTouched, handleSubmit }) => {
            const { email, password } = values;

            return (
              <LoginForm
                email={email}
                password={password}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                onCreateAccount={onCreateAccount}
                onSignIn={handleSubmit}
                isSigningIn={isSigningIn}
              />
            );
          }}
        </Formik>
      </LoginFormWrapper>
    </MainContainer>
  );
};

export default LoginTemplate;
export type { Props };
