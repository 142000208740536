import { useCallback } from 'react';
import { signOut } from 'firebase/auth';

import { firebaseAuth } from '../../firebase';

import { AuthHooks } from '.';
import { useGlobalState } from '../global';

const useSignOut: AuthHooks['useSignOut'] = (): {
  signOutUser: () => Promise<void>;
} => {
  const { useCurrentUser } = useGlobalState();
  const { setCurrentUser } = useCurrentUser;

  const signOutUser = useCallback(async () => {
    await signOut(firebaseAuth);
    setCurrentUser(undefined);
  }, []);

  return { signOutUser };
};

export default useSignOut;
