import styled from '@emotion/styled';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';

import Todo from 'domain/entities/todo';
import TodoList from 'components/organisms/TodoList';

const ContentContainer = styled.div`
  padding: 64px 0;
`;

type Props = {
  todoItems: Todo[];
  onSignOut: () => void;
};

const TodoTemplate = ({ todoItems, onSignOut }: Props): React.ReactElement => {
  const handleEditTodo = (todo: Todo) => {
    console.log(todo);
  };

  const handleDeleteTodo = (todo: Todo) => {
    console.log(todo);
  };

  return (
    <>
      <AppBar position='fixed'>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            Todos
          </Typography>
          <IconButton color='inherit' onClick={onSignOut}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ContentContainer>
        <TodoList items={todoItems} onEditTodo={handleEditTodo} onDeleteTodo={handleDeleteTodo} />
      </ContentContainer>
    </>
  );
};

export default TodoTemplate;
export type { Props };
