import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAuthHooks } from 'hooks/auth';
import TodoTemplate from 'components/templates/Todo';

const TodoPage = (): React.ReactElement => {
  const navigate = useNavigate();

  const { useSignOut } = useAuthHooks();
  const { signOutUser } = useSignOut();

  const { mutate: signOutMutation } = useMutation(signOutUser, {
    onSuccess: () => {
      navigate('/signin');
    },
  });

  return <TodoTemplate todoItems={[]} onSignOut={signOutMutation} />;
};

export default TodoPage;
