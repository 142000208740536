import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

type Props = {
  onEdit: () => void;
  onDelete: () => void;
};

const TodoMoreMenu = ({ onEdit, onDelete }: Props): React.ReactElement => {
  const [moreIconAnchorEl, setMoreIconAnchorEl] = useState<null | HTMLElement>(null);

  const isMoreMenuOpen = Boolean(moreIconAnchorEl);

  const handleClickMore = (event: React.MouseEvent<HTMLElement>) => {
    setMoreIconAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMoreIconAnchorEl(null);
  };

  const handleClickEdit = () => {
    handleCloseMoreMenu();
    onEdit();
  };

  const handleClickDelete = () => {
    handleCloseMoreMenu();
    onDelete();
  };

  return (
    <>
      <IconButton aria-label='settings' onClick={handleClickMore}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={moreIconAnchorEl} open={isMoreMenuOpen} onClose={handleCloseMoreMenu}>
        <MenuItem onClick={handleClickEdit}>Edit</MenuItem>
        <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default TodoMoreMenu;
export type { Props };
