import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import TodoPage from 'components/pages/TodoPage';

const ProtectedRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/todo' />} />
      <Route path='todo' element={<TodoPage />} />
      <Route path='*' element={<div>Page not found</div>} />
    </Routes>
  );
};

export default ProtectedRoutes;
