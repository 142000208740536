import { createContext, useContext } from 'react';

import { UserLogin } from '../../domain/entities/user';

type AuthHooks = {
  useSignIn: () => {
    signInUser: (credentials: UserLogin) => Promise<void>;
  };
  useSignOut: () => {
    signOutUser: () => Promise<void>;
  };
};

const AuthHooksContext = createContext<AuthHooks | null>(null);

const useAuthHooks = (): AuthHooks => {
  const context = useContext(AuthHooksContext);
  if (!context) {
    throw new Error('Context error');
  }
  return context;
};

export default AuthHooksContext;
export { useAuthHooks };
export type { AuthHooks };
