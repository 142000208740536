import styled from '@emotion/styled';

import Todo from 'domain/entities/todo';
import TodoCard from 'components/molecules/TodoCard';

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const TodoCardWrapper = styled.div`
  width: calc((100% / 4) - 20px);
  margin: 10px;
`;

type Props = {
  items: Todo[];
  onEditTodo: (todo: Todo) => void;
  onDeleteTodo: (todo: Todo) => void;
};

const TodoList = ({ items, onEditTodo, onDeleteTodo }: Props): React.ReactElement => {
  return (
    <ListContainer>
      {items.map((item, index) => {
        return (
          <TodoCardWrapper key={index}>
            <TodoCard data={item} onMenuEdit={onEditTodo} onMenuDelete={onDeleteTodo} />
          </TodoCardWrapper>
        );
      })}
    </ListContainer>
  );
};

export default TodoList;
export type { Props };
