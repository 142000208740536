import React from 'react';
import { GlobalStateProvider } from './global';
import AuthHooksContext, { useSignIn, useSignOut } from './auth';

type Props = { children?: React.ReactElement | React.ReactElement[] };

const Hooks = ({ children }: Props): React.ReactElement => {
  return (
    <GlobalStateProvider>
      <AuthHooksContext.Provider value={{ useSignIn, useSignOut }}>
        {children}
      </AuthHooksContext.Provider>
    </GlobalStateProvider>
  );
};

export default Hooks;
