import { useCallback } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { firebaseAuth } from '../../firebase';

import { AuthHooks } from '.';
import { UserLogin } from '../../domain/entities/user';
import { useGlobalState } from '../global';

const useSignIn: AuthHooks['useSignIn'] = (): {
  signInUser: (credentials: UserLogin) => Promise<void>;
} => {
  const { useCurrentUser } = useGlobalState();
  const { setCurrentUser } = useCurrentUser;

  const signInUser = useCallback(async (credentials: UserLogin) => {
    const { email, password } = credentials;

    const response = await signInWithEmailAndPassword(firebaseAuth, email, password);
    setCurrentUser(response.user);
  }, []);

  return { signInUser };
};

export default useSignIn;
