import { FormikTouched, FormikErrors } from 'formik';
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import reactLogo from 'logo.svg';
import { UserLogin } from 'domain/entities/user';
import Button from 'components/atoms/Button';

const StyledCard = styled(Card)`
  padding: 50px 20px 50px 20px;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  text-align: center;

  & > div {
    padding-bottom: 30px;
  }
`;

const StyledCardActions = styled(CardActions)`
  padding: 16px;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

type Props = {
  errors?: FormikErrors<UserLogin>;
  touched?: FormikTouched<UserLogin>;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  onCreateAccount: () => void;
  onSignIn: () => void;
  isSigningIn?: boolean;
} & UserLogin;

const LoginForm = ({
  email,
  password,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  onCreateAccount,
  onSignIn,
  isSigningIn,
}: Props): React.ReactElement => {
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('email', event.target.value);
  };

  const onBlurEmail = () => {
    setFieldTouched('email', true);
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('password', event.target.value);
  };

  const onBlurPassword = () => {
    setFieldTouched('password', true);
  };

  return (
    <StyledCard>
      <StyledCardContent>
        <TitleContainer>
          <img src={reactLogo} className='App-logo' alt='React Logo' />
          <Typography variant='h3'>Todo App</Typography>
        </TitleContainer>
        <TextField
          id='outlined-basic'
          label='Email'
          variant='outlined'
          type='email'
          value={email}
          error={!!errors?.email && touched?.email}
          helperText={touched?.email && errors?.email}
          onChange={onChangeEmail}
          onBlur={onBlurEmail}
        />
        <TextField
          id='outlined-basic'
          label='Password'
          variant='outlined'
          type='password'
          value={password}
          error={!!errors?.password && touched?.password}
          helperText={touched?.password && errors?.password}
          onChange={onChangePassword}
          onBlur={onBlurPassword}
        />
      </StyledCardContent>
      <StyledCardActions>
        <Button variant='text' onClick={onCreateAccount}>
          Create Account
        </Button>
        <Button variant='contained' onClick={onSignIn} disabled={isSigningIn} loading={isSigningIn}>
          Sign In
        </Button>
      </StyledCardActions>
    </StyledCard>
  );
};

export default LoginForm;
export type { Props };
