import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useGlobalState } from 'hooks/global';

import { ProtectedRoutes, UnprotectedRoutes } from './';

const RootNavigator = (): React.ReactElement => {
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  return <BrowserRouter>{currentUser ? <ProtectedRoutes /> : <UnprotectedRoutes />}</BrowserRouter>;
};

export default RootNavigator;
