import { createContext, useContext } from 'react';
import { AlertProps } from '@mui/material/Alert';

type GlobalState = {
  useCurrentUser: {
    currentUser: object | undefined;
    setCurrentUser: (user?: object) => void;
  };
  useSnackbarAlert: {
    alert: AlertProps | undefined;
    setAlert: (alert?: AlertProps) => void;
  };
};

const GlobalStateContext = createContext<GlobalState | null>(null);

const useGlobalState = (): GlobalState => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('Context error');
  }
  return context;
};

export default GlobalStateContext;
export { useGlobalState };
export type { GlobalState };
