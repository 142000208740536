import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import './App.css';

import { useGlobalState } from 'hooks/global';
import RootNavigator from 'navigators';

const App = (): React.ReactElement => {
  const { useSnackbarAlert } = useGlobalState();
  const { alert, setAlert } = useSnackbarAlert;

  const handleSnackbarClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert(undefined);
  };
  return (
    <>
      <RootNavigator />
      {!!alert && (
        <Snackbar open={!!alert} autoHideDuration={5000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity={alert?.severity}>
            {alert?.children}
          </MuiAlert>
        </Snackbar>
      )}
    </>
  );
};

export default App;
