import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import LoginPage from 'components/pages/LoginPage';

const UnprotectedRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/signin' />} />
      <Route path='signin' element={<LoginPage />} />
      <Route path='*' element={<div>Page not found</div>} />
    </Routes>
  );
};

export default UnprotectedRoutes;
