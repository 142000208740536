import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Todo from 'domain/entities/todo';

import TodoMoreMenu from '../TodoMoreMenu';

type Props = {
  data: Todo;
  onMenuEdit: (todo: Todo) => void;
  onMenuDelete: (todo: Todo) => void;
};

const TodoCard = ({ data, onMenuEdit, onMenuDelete }: Props): React.ReactElement => {
  const handleMenuEdit = () => {
    onMenuEdit(data);
  };

  const handleMenuDelete = () => {
    onMenuDelete(data);
  };

  return (
    <Card>
      <CardHeader
        title={data.title}
        action={<TodoMoreMenu onEdit={handleMenuEdit} onDelete={handleMenuDelete} />}
      />
      <CardContent>
        <Typography paragraph>{data.description}</Typography>
      </CardContent>
    </Card>
  );
};

export default TodoCard;
export type { Props };
