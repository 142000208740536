import React, { useState, useMemo, useEffect } from 'react';
import { AlertProps } from '@mui/material';

import GlobalStateContext, { GlobalState } from '.';

import { firebaseAuth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';

type Props = { value?: GlobalState; children?: React.ReactElement };

const Provider = (props: Props) => {
  const [currentUser, setCurrentUser] = useState<object | undefined>();
  const useCurrentUser = useMemo(
    () => ({
      currentUser,
      setCurrentUser: (user?: object) => {
        setCurrentUser(user);
      },
    }),
    [currentUser],
  );

  const [alert, setAlert] = useState<AlertProps | undefined>();
  const useSnackbarAlert = useMemo(
    () => ({
      alert,
      setAlert: (alert?: AlertProps) => {
        setAlert(alert);
      },
    }),
    [alert],
  );

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) setCurrentUser(user);
    });
  }, []);

  return <GlobalStateContext.Provider value={{ useCurrentUser, useSnackbarAlert }} {...props} />;
};

export default Provider;
