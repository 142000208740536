import * as Yup from 'yup';
import words from 'constants/words';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid Email')
    .required(words.validationMessage.fieldIsRequired),
  password: Yup.string().required(words.validationMessage.fieldIsRequired),
});

export default LoginSchema;
