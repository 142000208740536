import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  loading?: boolean;
} & ButtonProps;

const Component = ({ loading = false, children, ...props }: Props): React.ReactElement => {
  return <Button {...props}>{loading ? <CircularProgress color='inherit' /> : children}</Button>;
};

export default Component;
export type { Props };
